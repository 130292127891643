/* @flow */

import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { Link, NavLink, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import CartCounter from "components/AppHeader/CartCounter";
import { QuoteData } from "data";
import { useData } from "crustate/react";
// import CartMini from "components/CartMini";
import SystemMessages from "components/SystemMessages";
import CustomerBalance from "components/CustomerBalance";
import useSessionStorage from "helpers/use-session-storage";
import SearchMenu from "../SearchMenu";
import Drawer from "../Drawer";
import ChevronIcon from "icons/chevron.svg";
import SearchIcon from "icons/search.svg";
import Logo from "../logo.svg";

import styles from "./styles.scss";
import { useViewMode } from "context/view-mode";

import { CartMini } from "@crossroads/ui-components";

type Props = {
  className: string,
  customer: ?Customer,
  onCheckout: boolean,
};

export const collectorEnabledCountries = ["sv_SE", "da_DK", "fi_FI", "nb_NO"];

const AppHeaderLarge = ({ className = "", customer, onCheckout }: Props): React$Node => {
  const location = useLocation();
  const { categories, info: { locale } } = useContext(StoreInfoContext);
  const t = useTranslate();
  const [viewMode, setViewMode] = useViewMode();
  const onRedeem = (location ? location.pathname : "").includes("redeem");
  const toggleSearch = () => setViewMode(viewMode === "search" ? "normal" : "search");
  const toggleCategories = () => setViewMode(viewMode === "categories" ? "normal" : "categories");
  const toggleCart = () => setViewMode(viewMode === "cart" ? "normal" : "cart");
  const quoteData = useData(QuoteData);
  const activeRedeem = quoteData.data && quoteData.data.retain24validation;
  const showBuyLink = collectorEnabledCountries.includes(locale);
  const [isolationMode] = useSessionStorage("navIsolationMode", "false");
  const navIsolationMode = isolationMode === "true";

  const showHeader = true;

  return (
    <div className={styles.container}>
      <div className={cn(
        styles.block,
        { [styles.onRedeem]: onRedeem },
        className
      )}
      >
        <Wrapper className={styles.wrapper}>
          <div className={styles.left}>
            {navIsolationMode ? (
              <div className={styles.padding}>
                <Logo
                  alt={t("LOGO.ALT")}
                  title={t("LOGO.TITLE")}
                  className={styles.logo}
                />
              </div>
            ) : (
              <Link className={cn(styles.item, styles.padding)} to="/">
                <Logo
                  alt={t("LOGO.ALT")}
                  title={t("LOGO.TITLE")}
                  className={styles.logo}
                  onClick={() => setViewMode("normal")}
                />
              </Link>
            )}

            {!onCheckout && !navIsolationMode &&
              <nav className={styles.nav}>
                {!activeRedeem && showBuyLink &&
                  <NavLink
                    to="/zupergift"
                    activeClassName={styles.active}
                    className={cn(styles.item, styles.padding)}
                  >
                    {t("HEADER.BUY_ZUPERGIFT")}
                  </NavLink>
                }

                {!activeRedeem &&
                  <NavLink
                    to="/redeem"
                    activeClassName={styles.active}
                    className={cn(styles.item, styles.padding)}
                  >
                    {t("HEADER.REDEEM_GIFTCARD")}
                  </NavLink>
                }

                <NavLink
                  to={t("ROUTES.ALL_GIFTCARDS")}
                  activeClassName={styles.active}
                  className={cn(styles.item, styles.padding)}
                >
                  {t("HEADER.ALL_GIFTCARDS")}
                </NavLink>

                <span
                  className={
                    cn(
                      styles.item,
                      styles.item__categories,
                      { [styles.active]: viewMode === "categories" },
                      styles.padding
                    )
                  }
                  onClick={toggleCategories}
                >
                  {t("HEADER.CATEGORIES")}
                  <ChevronIcon />
                </span>

                <NavHashLink
                  smooth
                  to="/#about"
                  className={cn(styles.item, styles.padding, {
                    [styles.active]: `${location.pathname}${location.hash}` === "/#about",
                  })}
                  location={{ pathname: location.pathname + location.hash }}
                >
                  {t("HEADER.ABOUT")}
                </NavHashLink>

                <NavLink
                  to="/faq"
                  activeClassName={styles.active}
                  className={cn(styles.item, styles.padding)}
                >
                  {t("HEADER.HELP")}
                </NavLink>
              </nav>
            }
          </div>

          <div className={styles.right}>
            <nav className={styles.nav}>
              {!onCheckout && !navIsolationMode &&
                <span
                  className={
                    cn(styles.item, { [styles.active]: viewMode === "search" }, styles.padding)
                  }
                  onClick={toggleSearch}
                >
                  <SearchIcon />
                </span>
              }
              <CartCounter className={cn(styles.item, styles.cartCounter, styles.padding)} />
              <CustomerBalance customer={customer} />
            </nav>
          </div>
        </Wrapper>
      </div>

      {viewMode === "search" &&
        <nav className={styles.search}>
          <SearchMenu />
        </nav>
      }

      {viewMode === "categories" &&
        <nav className={styles.categories}>
          <Drawer
            setMode={setViewMode}
            heading={t("HEADER.CATEGORIES")}
            closeText={t("HEADER.CLOSE_CATEGORIES")}
            items={categories}
            onClose={toggleCategories}
          />
        </nav>
      }

      {viewMode === "cart" &&
        <Wrapper>
          <CartMini
            cartUrl="/checkout"
            variant="zupergift"
            showHeader={showHeader}
            quoteData={quoteData}
            onClose={toggleCart}
          />
        </Wrapper>
      }

      {viewMode !== "normal" && viewMode !== "language-switcher" &&
        <div className={styles.dim} onClick={() => setViewMode("normal")} />
      }

      <Wrapper>
        <SystemMessages />
      </Wrapper>
    </div>
  );
};

export default AppHeaderLarge;
