/* @flow */

import type { QuoteResponse, QuoteRequest } from "state/quote";
import type { Quote } from "shop-state/types";

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { useClient } from "entrypoint/shared";
import {
  placeOrder as placeOrderQuery,
  quote as quoteQuery,
  setQuoteBillingAddressPlaceholder,
  setQuotePaymentMethodFree } from "queries";
import {
  QUOTE_PLACE_ORDER_REQUEST,
  QUOTE_PLACE_ORDER_RESPONSE,
  syncQuote,
  setEmail } from "@crossroads/shop-state/quote";
import { useSendMessage } from "crustate/react";
import { addMessage, addMessageTranslated } from "@crossroads/shop-state/messages";
import { focusInvalidField } from "helpers/utils";
import { useHistory } from "react-router-dom";
import { Form, rules, isEmail, isRequired, match } from "@awardit/formaggio";
import Field, { CheckboxField } from "components/Field";
import { Button } from "@crossroads/ui-components";
import styles from "./styles.scss";

type Props = {
  isProcessing: boolean,
  setProcessing: boolean => void,
  className?: string,
  quote: Quote,
};

type FormState = {
  email: string,
  emailRepeat: string,
  terms: boolean,
};

export const METHOD_CODE = "free";

const validation = rules([
  isRequired("email"),
  isEmail("email"),
  isRequired("emailRepeat"),
  match("email", "emailRepeat"),
  isRequired("terms"),
]);

const Free = ({ isProcessing, setProcessing, className, quote }: Props): React$Node => {
  const t = useTranslate();
  const client = useClient();
  const sendMessage = useSendMessage();
  const { push } = useHistory();
  const [state, setState] = useState<FormState>({
    email: "",
    emailRepeat: "",
    terms: false,
  });

  useEffect(() => {
    const setPaymentMethod = async () => {
      const result = await client(setQuotePaymentMethodFree);

      if (result.setQuotePaymentMethodFree.free.result === "success") {
        const newQuoteData = await client(quoteQuery);

        if (newQuoteData.quote) {
          sendMessage(syncQuote(newQuoteData.quote));
        }
      }
    };

    if (quote.payment?.code !== METHOD_CODE) {
      setPaymentMethod();
    }
  }, []);

  const errors = validation((state: any));

  const submit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    setProcessing(true);

    await client(setQuoteBillingAddressPlaceholder);

    await sendMessage(setEmail(state.email));

    try {
      const placeOrderData = await client(placeOrderQuery);
      const newQuoteData = await client(quoteQuery);
      const newQuote = newQuoteData.quote;

      // Handle place order error
      if (placeOrderData && placeOrderData.placeOrder.result !== "success") {
        sendMessage(addMessage(placeOrderData.placeOrder.result, "error"));

        // Sync quote and return
        if (newQuote) {
          sendMessage(syncQuote(newQuote));
        }

        return;
      }

      // Sync customer to get the new point balance
      sendMessage(syncQuote(newQuote));

      if (newQuote) {
        sendMessage(({
          tag: QUOTE_PLACE_ORDER_REQUEST,
        }: QuoteRequest));

        sendMessage(({
          tag: QUOTE_PLACE_ORDER_RESPONSE,
          data: newQuote,
        }: QuoteResponse));
      }

      push("/checkout/success");
    }
    catch (e_) {
      if (e_.name === "QueryError" && Array.isArray(e_.errors)) {
        e_.errors.forEach(error => {
          if (error.extensions &&
            error.extensions.category === "retain24" &&
            error.extensions.code === "retain24_product_category_disallowed") {
            sendMessage(addMessage("NOT_ALLOWED_TO_PLACE_ORDER_WITH_PRODUCTS", "error"));
          }
        });
      }
      else {
        sendMessage(addMessageTranslated(e_.message, "error"));
      }
    }
    finally {
      setProcessing(false);
    }
  };

  return (
    <div className={cn(styles.block, className)}>
      <h3 className={styles.heading}>{t("CHECKOUT.DELIVERY")}</h3>
      <p>
        {t("CHECKOUT.DELIVERY_NOTICE")}
      </p>

      <Form
        value={(state: any)}
        errors={errors}
        onError={focusInvalidField}
        onChange={x => {
          setState({ ...state, ...(x: any) });
        }}
        onSubmit={submit}
      >
        <div className={styles.row}>
          <Field name="email" autoComplete="email" label={t("CHECKOUT.EMAIL")} />
        </div>
        <div className={styles.row}>
          <Field name="emailRepeat" label={t("CHECKOUT.EMAIL_REPEAT")} />
        </div>
        <div
          className={styles.row}
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <CheckboxField name="terms" className={styles.checkbox}>
            <span>{t("CHECKOUT.TERMS.I_ACCEPT")} <a href={t("CHECKOUT.TERMS.TERMS_URL")} rel="noopener noreferrer" target="_blank">{t("CHECKOUT.TERMS.THE_TERMS")}</a> {t("CHECKOUT.TERMS.FOR_THIS_PURCHASE")}</span>
          </CheckboxField>
        </div>
        <div className={styles.row}>
          <Button className={styles.button} variant="primary" loading={isProcessing}>{t("CHECKOUT.PLACE_ORDER")}</Button>
        </div>
      </Form>

    </div>
  );
};

export default Free;
