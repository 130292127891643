/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import useSessionStorage from "helpers/use-session-storage";
import { useViewMode } from "context/view-mode";

import styles from "./styles.scss";

type Props = {
  className: string,
  setToggleCookieDialogue: (value: boolean) => void,
};

const AppFooter = ({ className = "", setToggleCookieDialogue }: Props): React$Node => {
  const { categories } = useContext(StoreInfoContext);
  const [, setViewMode] = useViewMode();
  const [isolationMode] = useSessionStorage("navIsolationMode", "false");
  const navIsolationMode = isolationMode === "true";
  const t = useTranslate();

  return (
    <div className={cn(styles.block, className)}>
      <Wrapper className={styles.wrapper}>
        <p>
          {t("FOOTER.LANGUAGE_SWITCHER_TEXT")}
          <Button
            className={cn(styles.languageSwitcher, styles.button)}
            onClick={() => {
              setViewMode("language-switcher");
            }}
          >
            {t("FOOTER.LANGUAGE_SWITCHER_BUTTON")}
          </Button>
        </p>
        <Button
          className={styles.button}
          onClick={() => setToggleCookieDialogue(true)}
        >
          {t("FOOTER.COOKIES")}
        </Button>
      </Wrapper>
      {!navIsolationMode &&
        <Wrapper className={styles.wrapper}>
          <p>{t("FOOTER.CATEGORIES")}</p>
          <div className={styles.categories}>
            {categories.map(x => (
              <div key={x.name} className={styles.category}>
                <Link
                  to={{
                    pathname: x.url,
                    state: { hint: { type: "category", category: x } },
                  }}
                >
                  {x.name}
                </Link>
              </div>
            ))}
          </div>
        </Wrapper>
      }
      <Wrapper className={cn(styles.wrapper, { [styles.copyright]: !navIsolationMode })}>
        <p>{t("FOOTER.COPYRIGHT", { currentYear: new Date().getFullYear() })} <a href="https://awardit.com" rel="noopener noreferrer" target="_blank">Awardit AB (publ)</a></p>
        <p><small dangerouslySetInnerHTML={{ __html: t("FOOTER.RECAPTCHA") }} /></p>
      </Wrapper>
    </div>
  );
};

export default AppFooter;
