/* @flow */

import React, { useEffect, useLayoutEffect } from "react";
import cn from "classnames";
import usePrevious from "helpers/use-previous";
import styles from "./styles.scss";
import { collector } from "config";

type Props = {
  className?: string,
  publicToken: string,
  lang: string,
  onCustomerUpdated?: () => void,
  onOrderValidationFailed?: () => void,
  onLocked?: () => void,
  onUnlocked?: () => void,
  onReloadedByUser?: () => void,
  onExpired?: () => void,
  onResumed?: () => void,
  onPurchaseCompleted?: () => void,
  onLoad?: () => void,
  onCheckoutShippingUpdated?: () => void,
  updatingQuote: boolean,
};

export const METHOD_CODE = "Crossroads_CollectorCheckout";

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

const CollectorCheckout = ({
  className,
  publicToken,
  lang,
  onCustomerUpdated,
  onOrderValidationFailed,
  onLoad,
  onLocked,
  onUnlocked,
  onReloadedByUser,
  onExpired,
  onResumed,
  onPurchaseCompleted,
  onCheckoutShippingUpdated,
  updatingQuote }: Props): React$Node => {
  const prevUpdatingQuote = usePrevious(updatingQuote);
  const _onCustomerUpdated = () => {
    if (typeof onCustomerUpdated === "function") {
      onCustomerUpdated();
    }
  };

  useEffect(() => {
    if (window && window.collector) {
      if (prevUpdatingQuote !== updatingQuote && updatingQuote) {
        window.collector.checkout.api.suspend();
      }
      else if (prevUpdatingQuote !== updatingQuote && !updatingQuote) {
        window.collector.checkout.api.resume();
      }
    }
  }, [updatingQuote]);

  const _onOrderValidationFailed = () => {
    if (typeof onOrderValidationFailed === "function") {
      onOrderValidationFailed();
    }
  };

  const _onLocked = () => {
    if (typeof onLocked === "function") {
      onLocked();
    }
  };

  const _onUnlocked = () => {
    if (typeof onUnlocked === "function") {
      onUnlocked();
    }
  };

  const _onReloadedByUser = () => {
    if (typeof onReloadedByUser === "function") {
      onReloadedByUser();
    }
  };

  const _onExpired = () => {
    if (typeof onExpired === "function") {
      onExpired();
    }
  };

  const _onResumed = () => {
    if (typeof onResumed === "function") {
      onResumed();
    }
  };

  const _onPurchaseCompleted = () => {
    if (typeof onPurchaseCompleted === "function") {
      onPurchaseCompleted();
    }
  };

  const _onLoad = () => {
    if (typeof onLoad === "function") {
      onLoad();
    }
  };

  const _onCheckoutShippingUpdated = () => {
    if (typeof onCheckoutShippingUpdated === "function") {
      onCheckoutShippingUpdated();
    }
  };

  useIsomorphicLayoutEffect(() => {
    if (document) {
      const currentScript = document.querySelector("#collector_checkout_script");

      if (!currentScript) {
        const head = document.querySelector("head");
        const script = document.createElement("script");

        script.src = collector.src;
        script.setAttribute("id", "collector_checkout_script");
        script.dataset.lang = lang;
        script.dataset.token = publicToken;
        script.dataset.containerId = "collectorDiv";
        script.dataset.actionColor = styles.primaryColor;
        script.dataset.actionTextColor = styles.textColor;
        script.dataset.version = "v1";

        /* eslint-disable unicorn/prefer-add-event-listener */
        script.onload = _onLoad;
        /* eslint-enable unicorn/prefer-add-event-listener */

        if (head) {
          /* eslint-disable unicorn/prefer-dom-node-append */
          head.appendChild(script);
          /* eslint-enable unicorn/prefer-dom-node-append */
        }
      }
      else if (window && window.collector) {
        window.collector.checkout.api.init();
      }

      document.addEventListener("collectorCheckoutCustomerUpdated", _onCustomerUpdated);
      document.addEventListener("collectorCheckoutOrderValidationFailed", _onOrderValidationFailed);
      document.addEventListener("collectorCheckoutLocked", _onLocked);
      document.addEventListener("collectorCheckoutUnlocked", _onUnlocked);
      document.addEventListener("collectorCheckoutReloadedByUser", _onReloadedByUser);
      document.addEventListener("collectorCheckoutExpired", _onExpired);
      document.addEventListener("collectorCheckoutResumed", _onResumed);
      document.addEventListener("collectorCheckoutPurchaseCompleted", _onPurchaseCompleted);
      document.addEventListener("collectorCheckoutShippingUpdated", _onCheckoutShippingUpdated);
    }

    return () => {
      if (document) {
        document.removeEventListener("collectorCheckoutCustomerUpdated", _onCustomerUpdated);
        document.removeEventListener("collectorCheckoutOrderValidationFailed", _onOrderValidationFailed);
        document.removeEventListener("collectorCheckoutLocked", _onLocked);
        document.removeEventListener("collectorCheckoutUnlocked", _onUnlocked);
        document.removeEventListener("collectorCheckoutReloadedByUser", _onReloadedByUser);
        document.removeEventListener("collectorCheckoutExpired", _onExpired);
        document.removeEventListener("collectorCheckoutResumed", _onResumed);
        document.removeEventListener("collectorCheckoutPurchaseCompleted", _onPurchaseCompleted);
        document.removeEventListener("collectorCheckoutShippingUpdated", _onCheckoutShippingUpdated);

        const currentScript = document.querySelector("#collector_checkout_script");

        if (currentScript) {
          currentScript.remove();
        }

        if (window && window.collector) {
          delete window.collector;
        }
      }
    };
  }, []);

  return (
    <div className={cn(styles.collector, className)}>
      <div id="collectorDiv" />
    </div>
  );
};

export default CollectorCheckout;
