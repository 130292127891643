/* @flow */

import type { Quote } from "shop-state/types";

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useClient } from "entrypoint/shared";
import { getPaymentMethods } from "queries";

import Collector from "./Collector";
import { METHOD_CODE as COLLECTOR_CODE } from "./Collector/CollectorCheckout";
import Free from "./Free";
import styles from "./styles.scss";

type Props = {
  quote: Quote,
  updatingQuote: boolean,
  className?: string,
  isLocked: boolean,
  setIsLocked: boolean => void,
};

const PaymentMethods =
  ({ quote, updatingQuote, className, isLocked, setIsLocked }: Props): React$Node => {
    const { grandTotal } = quote;
    const canAfford = grandTotal.incVat === 0;
    const client = useClient();
    const [paymentMethods, setPaymentMethods] = useState<Array<string>>([]);
    const collectorEnabled = paymentMethods.includes(COLLECTOR_CODE);

    useEffect(() => {
      (async () => {
        if (!updatingQuote) {
          const paymentMethodsData = await client(getPaymentMethods);

          if (paymentMethodsData && paymentMethodsData.paymentMethods) {
            setPaymentMethods(paymentMethodsData.paymentMethods.map(pm => pm.code));
          }
        }
      })();
    }, [updatingQuote, client]);

    return (
      <div className={cn(styles.block, className)}>
        {canAfford &&
        <Free
          className={styles.free}
          quote={quote}
          isProcessing={isLocked}
          setProcessing={setIsLocked}
        />
        }
        {!canAfford && collectorEnabled &&
        <Collector
          className={styles.collector}
          quote={quote}
          updatingQuote={updatingQuote}
          setProcessing={setIsLocked}
        />
        }
      </div>
    );
  };

export default PaymentMethods;

