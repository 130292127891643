/* @flow */

import type { CmsPage } from "shop-state/types";

import React from "react";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import Breadcrumbs from "components/Breadcrumbs";
import { useGetCmsMeta } from "helpers/get-meta";

type Props = {
  cmsPage: CmsPage,
};

const CmsPageView = ({ cmsPage }: Props): React$Node => {
  const meta = useGetCmsMeta(cmsPage);

  return (
    <Wrapper>
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      <Breadcrumbs current={cmsPage.contentHeading} />

      <h1>{cmsPage.title}</h1>

      <div key={cmsPage.url} dangerouslySetInnerHTML={{ __html: cmsPage.content }} />
    </Wrapper>
  );
};

export default CmsPageView;
