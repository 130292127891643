/* @flow */

import type {
  AnalyticsProviderProps,
  AnalyticsContextType as GoogleAnalyticsContextType,
  ProductSubset,
  Order,
  Quote } from "shop-state/types";

import React, { createContext, useContext, useEffect, useMemo } from "react";
import { WithAnalytics, AnalyticsContext as GoogleAnalyticsContext } from "@crossroads/analytics";
import fbq from "helpers/fb-pixel";
import ttq from "helpers/tt-pixel";
import tdconv from "helpers/tdconv";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { useData } from "crustate/react";
import { QuoteData } from "data";

type Props = AnalyticsProviderProps & {
  children: React$Node,
};

type PropsInner = {
  children: React$Node,
};

type AnalyticsContextType = {
  ...GoogleAnalyticsContextType,
  registerModifyCart: (product: ProductSubset, diff: number, value: number) => void,
  registerCheckoutSuccess: (order: Order, coupon?: string) => void,
  registerBeginCheckoutProcess: (quote: Quote, value: number) => void,
  grantConsentNecessary: () => void,
  grantConsentStatistical: () => void,
  grantConsentMarketing: () => void,
  denyConsentStatistical: () => void,
  denyConsentMarketing: () => void,
};

/**
 * No operation function
 */
export const noop = (): typeof undefined => undefined;

const AnalyticsContext = createContext<AnalyticsContextType>({
  g: noop,
  register: (): number => 0,
  unregister: noop,
  registerProductClick: noop,
  registerProductDetailsView: noop,
  registerModifyCart: noop,
  registerBeginCheckoutProcess: noop,
  registerCheckoutProgress: noop,
  registerCheckoutStep: noop,
  registerCheckoutSuccess: noop,
  grantConsentNecessary: noop,
  grantConsentStatistical: noop,
  grantConsentMarketing: noop,
  denyConsentStatistical: noop,
  denyConsentMarketing: noop,
  searchTerm: noop,
  viewedCart: noop,
});

const AnalyticsProvider = ({ children, ...rest }: Props): React$Node => {
  return (
    <WithAnalytics {...rest}>
      <AnalyticsProviderInner>
        {children}
      </AnalyticsProviderInner>
    </WithAnalytics>
  );
};

const AnalyticsProviderInner = ({ children }: PropsInner): React$Node => {
  const gaContext = useContext(GoogleAnalyticsContext);
  const t = useTranslate();
  const { info } = useContext(StoreInfoContext);
  const data = useData(QuoteData);
  const voucherValue = data.data &&
    data.data.retain24validation ?
    data.data.retain24validation.value : 0;

  useEffect(() => {
    if (info &&
      info.tradedoubler &&
      info.tradedoubler.organizationId
    ) {
      tdconv.init(info.tradedoubler.organizationId);
    }
  }, [info, data]);

  const registerModifyCart = (product: ProductSubset, diff: number, value: number) => {
    if (diff === 0) {
      return;
    }

    gaContext.registerModifyCart(product, diff > 0 ? "add_to_cart" : "remove_from_cart", value);

    /* eslint-disable camelcase */
    if (diff > 0) {
      fbq.event("AddToCart", {
        content_name: product.name,
        content_ids: [product.sku],
        content_type: "product",
        contents: [{ id: product.sku, quantity: diff }],
        value: Math.round(product.price.exVat * diff),
        currency: t("LOCALE.CURRENCY"),
      });

      ttq.event("AddToCart", {
        content_name: product.name,
        content_id: product.sku,
        content_type: "product",
        contents: [
          {
            content_id: product.sku,
            content_type: "product",
            quantity: diff,
          },
        ],
        value: Math.round(product.price.exVat * diff),
        currency: t("LOCALE.CURRENCY"),
      });
    }
    /* eslint-enable camelcase */
  };

  const registerBeginCheckoutProcess = (quote: Quote, value: number) => {
    gaContext.registerBeginCheckoutProcess(quote.items, value);

    /* eslint-disable camelcase */
    fbq.event("InitiateCheckout", {
      contents: quote.items.map(i => ({
        id: i.product.sku,
        quantity: i.qty,
        type: "product",
      })),
      content_type: "product",
      value: Math.round(quote.grandTotal.exVat),
      currency: t("LOCALE.CURRENCY"),
    });

    ttq.event("InitiateCheckout", {
      contents: quote.items.map(i => ({
        content_id: i.product.sku,
        quantity: i.qty,
        content_type: "product",
      })),
      content_type: "product",
      content_id: "InitiateCheckout",
      value: Math.round(quote.grandTotal.exVat),
      currency: t("LOCALE.CURRENCY"),
    });
    /* eslint-enable camelcase */
  };

  const registerCheckoutSuccess = (order: Order, coupon?: string) => {
    // Track orders that are only paid with card/swish,
    // if a gift card has been used the r24amount will be negative
    if (order.grandTotal.incVat > 0 && order.r24Amount === 0) {
      gaContext.g("set", "user_data", { email: order.email });
      gaContext.registerCheckoutSuccess(order, order.grandTotal.incVat || 0, coupon);
    }

    const fbOrderMap = order.items.map(i => {
      const p = i.configOption ? i.configOption.product : i.product;

      return { id: p.sku, quantity: i.qty };
    });

    // Track orders that are only paid with card/swish,
    // if a gift card has been used the r24amount will be negative
    if (order.grandTotal.exVat > 0 && order.r24Amount === 0) {
      /* eslint-disable camelcase */
      fbq.event("Purchase", {
        contents: fbOrderMap,
        content_type: "product",
        value: Math.round(order.grandTotal.exVat).toFixed(2),
        currency: t("LOCALE.CURRENCY"),
      });

      ttq.event("CompletePayment", {
        content_type: "product",
        content_id: "Complete Payment",
        contents: order.items.map(i => {
          const p = i.configOption ? i.configOption.product : i.product;

          return {
            content_id: p.sku,
            quantity: i.qty,
            content_type: "product",
          };
        }),
        value: Math.round(order.grandTotal.exVat),
        currency: t("LOCALE.CURRENCY"),
      });
    }

    if (info &&
      info.tradedoubler &&
      info.tradedoubler.organizationId) {
      if (order &&
        order.tradedoubler &&
        order.tradedoubler.sale &&
        order.tradedoubler.sale.length > 0
      ) {
        order.tradedoubler.sale.forEach(sale => {
          tdconv.event("track", "sale", {
            transactionId: order.id,
            ordervalue: sale.exVat,
            currency: t("LOCALE.CURRENCY"),
            event: sale.event,
            voucher: voucherValue,
          });
        });
      }
    }
    /* eslint-enable camelcase */
  };

  const grantConsentMarketing = () => {
    fbq.init();
    ttq.init();
    gaContext.grantConsentMarketing();
  };

  const grantConsentStatistical = () => {
    gaContext.grantConsentStatistical();
  };

  const denyConsentStatistical = () => {
    gaContext.denyConsentStatistical();
  };

  const value = useMemo(() => ({
    g: gaContext.g,
    register: gaContext.register,
    unregister: gaContext.unregister,
    registerProductClick: gaContext.registerProductClick,
    registerProductDetailsView: gaContext.registerProductDetailsView,
    registerModifyCart,
    registerBeginCheckoutProcess,
    registerCheckoutProgress: gaContext.registerCheckoutProgress,
    registerCheckoutStep: gaContext.registerCheckoutStep,
    registerCheckoutSuccess,
    grantConsentNecessary: gaContext.grantConsentNecessary,
    grantConsentStatistical,
    grantConsentMarketing,
    denyConsentStatistical,
    denyConsentMarketing: gaContext.denyConsentMarketing,
    searchTerm: gaContext.searchTerm,
    viewedCart: gaContext.viewedCart,
  }), []);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalytics = (): AnalyticsContextType => {
  const context = useContext(AnalyticsContext);

  return context;
};

export {
  AnalyticsProvider,
  useAnalytics,
};
