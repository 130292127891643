/* @flow */

import React, { useEffect } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useAnalytics } from "context/analytics";
import QtyPicker from "components/QtyPicker";
import { Link, useHistory } from "react-router-dom";
import { QuoteData } from "data";
import { useSendMessage, useData } from "crustate/react";
import { removeQuoteItem, updateQuoteItemQty } from "@crossroads/shop-state/quote";
import useFormat from "helpers/use-format";
import Spinner from "components/Spinner";
type Props = {
  isLocked: boolean,
  className?: string,
};

const Cart = ({ isLocked, className }: Props): React$Node => {
  const analytics = useAnalytics();
  const history = useHistory();
  const sendMessage = useSendMessage();
  const data = useData(QuoteData);
  const { formatPrice } = useFormat();

  useEffect(() => {
    if (data.data) {
      analytics.viewedCart(data.data.grandTotal.incVat, data.data.items.map(item => {
        return {
          sku: item.product.sku,
          name: item.product.name,
          price: {
            exVat: item.rowTotal.exVat,
            incVat: item.rowTotal.incVat,
            vat: item.rowTotal.incVat - item.rowTotal.exVat,
          },
          qty: item.qty,
          attributes: {
            manufacturer: item.product.attributes.manufacturer,
          },
          categories: item.product.categories,
        };
      }));
    }
  }, []);

  if (!data.data) {
    return null;
  }

  const processingItem = typeof data.processingItem !== "undefined" ? data.processingItem : null;
  const { items } = data.data;

  return (
    <div className={cn(styles.block, className)}>
      <div>
        {isLocked &&
          <div className={styles.spinner}>
            <Spinner />
          </div>
        }
        {items.map(x => {
          const product = x.configOption ? {
            ...x.product,
            ...x.configOption.product,
            categories: x.product.categories,
          } : x.product;

          return (
            <div
              key={x.itemBuyRequest}
              className={
                cn(styles.item, {
                  [styles.processing]: processingItem === x.itemBuyRequest,
                  [styles.disabled]: data.state === "UPDATING_ITEM" || isLocked,
                })}
            >
              <div className={styles.left}>
                <div style={{ display: "flex", position: "static" }}>
                  <img
                    className={styles.image}
                    alt={product.name}
                    src={product.attributes.image?.x1}
                    onClick={() => history.push(product.url)}
                  />
                  <div className={styles.info}>
                    <Link to={product.url} className={styles.name}>{product.name}</Link>
                    <p className={styles.brand}>{product.attributes.manufacturer}</p>
                  </div>
                </div>

                <QtyPicker
                  className={styles.qtyPicker}
                  value={x.qty}
                  min={0}
                  setValue={(v: number) => {
                    if (data.state === "UPDATING_ITEM" || isLocked) {
                      return;
                    }

                    if (v > 0) {
                      sendMessage(updateQuoteItemQty(x.itemBuyRequest, v));
                    }
                    else {
                      sendMessage(removeQuoteItem(x.itemBuyRequest));
                    }

                    analytics.registerModifyCart({
                      ...product,
                      qty: Math.abs(v - x.qty),
                    }, v - x.qty, product.price.incVat);
                  }} />
              </div>

              <div className={styles.right}>
                <span className={styles.price}>
                  {formatPrice(x.rowTotal.incVat)}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Cart;
