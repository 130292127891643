/* @flow */

import type { Location, RouterHistory as History } from "react-router";
import type { Node as ReactNode } from "react";

import React, { useContext, useRef, useEffect, useState } from "react";
import cn from "classnames";
import { usePreserveScrollPosition } from "react-router-on-navigation";
import { Helmet } from "react-helmet-async";
import AppHeader from "components/AppHeader";
import AppFooter from "components/AppFooter";
import { NotificationBar } from "@crossroads/ui-components";
import LanguageSwitcher from "components/LanguageSwitcher";
import CookieConsent from "components/CookieConsent";
import useSessionStorage from "helpers/use-session-storage";
import { parseParams } from "helpers/location-search-string";
import { StoreInfoContext } from "entrypoint/shared";
import { MessagesData, CmsData } from "data";
import useCookie from "helpers/use-cookie";
import { SubNavOpenProvider } from "context/sub-nav-open";
import { ViewModeProvider } from "context/view-mode";

import styles from "./styles.scss";

type Props = {
  children: ReactNode,
  location: Location,
  history: History,
};

const App = ({ children, location, history }: Props): React$Node => {
  const container = useRef(null);
  const [, setIsolationMode] = useSessionStorage("navIsolationMode", "false");
  const onCheckout = (location ? location.pathname : "").includes("checkout");
  const onRedeem = (location ? location.pathname : "").includes("redeem");
  const { info } = useContext(StoreInfoContext);
  const cookieOpts = { domain: `.${typeof window !== "undefined" ? window.location.host.replace(/:\d+$/, "") : ""}`, expires: 3650 };
  const [consent, setConsent, consentRead] = useCookie("cookie-consent", "initial", cookieOpts);
  const [hideNotificationBar, setHideNotificationBar] = useState(false);
  const [toggleCookieDialogue, setToggleCookieDialogue] = useState(false);

  useEffect(() => {
    const { navIsolationMode } = parseParams(location.search);

    if (navIsolationMode) {
      setIsolationMode("true");
    }
  }, []);

  usePreserveScrollPosition(history);

  return (
    <MessagesData.Provider>
      <ViewModeProvider>
        <SubNavOpenProvider>
          <div
            ref={container}
            className={cn(
              styles.block,
              { [styles.onCheckout]: onCheckout },
              { [styles.onRedeem]: onRedeem }
            )}
          >
            <CmsData.Provider url="cookie-consent">
              <Helmet
                titleTemplate={`${info.titlePrefix || ""} %s ${info.titleSuffix || ""}`}
                link={[
                  { rel: "apple-touch-icon", sizes: "180x180", href: "/assets/apple-touch-icon.png" },
                  { rel: "icon", type: "image/png", sizes: "32x32", href: "/assets/favicon-32x32.png" },
                  { rel: "icon", type: "image/png", sizes: "16x16", href: "/assets/favicon-16x16.png" },
                  { rel: "manifest", href: "/site.webmanifest" },
                  { rel: "manifest", href: "/manifest.json" },
                  { rel: "mask-icon", href: "/assets/safari-pinned-tab.svg", color: "#58b1db" },
                ]}
                meta={[
                  { name: "msapplication-TileColor", content: "#ffffff" },
                  { name: "theme-color", content: "#ffffff" },
                ]}
                htmlAttributes={{ lang: info.locale.slice(0, 2) }}
              />
              <div className={styles.top}>
                <AppHeader
                  className={styles.header}
                  location={location}
                  onCheckout={onCheckout}
                />

                <div key={location.pathname}>
                  {children}
                </div>

                {consentRead &&
                  <div className={styles.notices}>
                    <CookieConsent
                      isVisible={consentRead && (consent === "initial" || toggleCookieDialogue)}
                      consent={consent}
                      setConsent={setConsent}
                      consentRead={consentRead}
                      setToggleCookieDialogue={setToggleCookieDialogue}
                    />
                  </div>
                }
              </div>
              {!onCheckout &&
                <AppFooter
                  className={styles.footer}
                  setToggleCookieDialogue={setToggleCookieDialogue}
                />
              }
              {info.popUp && info.popUp.body && !hideNotificationBar &&
                <NotificationBar
                  className={styles.notificationBar}
                  text={info.popUp.body}
                  onClose={() => setHideNotificationBar(true)}
                />
              }
            </CmsData.Provider>
          </div>
          <LanguageSwitcher />
        </SubNavOpenProvider>
      </ViewModeProvider>
    </MessagesData.Provider>
  );
};

export default App;
