/* @flow */

import type { Location, RouterHistory as History } from "react-router";

import React from "react";
import { Route, Switch, Redirect } from "react-router";
import CmsPageView from "components/CmsPageView";
import {
  RouteData, OrderData, HomeData, BrandData, AllProductsData, SearchData } from "data";
import { useData } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import App from "components/App";
import CategoryView, { HintCategoryView } from "components/CategoryView";
import SearchView from "components/SearchView";
import ProductView, { HintProductView } from "components/ProductView";
import { ProductListViewWrapper } from "@crossroads/shop-views";
import HomeView from "components/HomeView";
import RedeemView from "components/RedeemView";
import SuccessView from "components/SuccessView";
import CheckoutView from "components/CheckoutView";
import NotFoundView from "components/NotFoundView";
import LoadingView from "components/LoadingView";
import ProductCard from "components/ProductCard";
import { useTranslate } from "@awardit/react-use-translate";

type RoutesProps = {
  location: Location,
  history: History,
};

type RouteViewProps = {
  location: Location,
  history: History,
};

const RouteView = ({ location, history }: RouteViewProps) => {
  const data = useData(RouteData);

  if (data.state === "LOADING") {
    if (data.hint) {
      switch (data.hint.type) {
        case "product":
          return <HintProductView product={data.hint.product} />;
        case "category":
          return <HintCategoryView category={data.hint.category} />;
        case "cms_page":
          return <CmsPageView cmsPage={data.hint.cmsPage} />;
        default:
      }
    }

    return <LoadingView />;
  }

  if (!data.route) {
    // TODO: Check error msg
    return <NotFoundView />;
  }

  switch (data.route.type) {
    case "product":
      return <ProductView product={data.route.product} />;
    case "category":
      return <CategoryView category={data.route.category} location={location} history={history} />;
    case "cms_page":
      return <CmsPageView cmsPage={data.route.cmsPage} />;
    default:
      return null;
  }
};

const Routes = ({ location, history }: RoutesProps) => {
  const t = useTranslate();

  return (
    <App location={location} history={history}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <HomeData.Provider>
              <HomeView />
            </HomeData.Provider>
          )} />

        <Route
          path="/redeem"
          component={RedeemView}
        />

        <Route
          path="/checkout/success"
          render={() => (
            <OrderData.Provider>
              <SuccessView />
            </OrderData.Provider>
          )} />

        <Route exact path="/checkout" component={CheckoutView} />

        <Route
          path="/varumarke/:brand"
          render={({ match }) => (
            <BrandData.Provider
              name={match.params.brand ?? ""}
              brand={decodeURIComponent(match.params.brand ?? "")}
            >
              <ProductListViewWrapper
                variant="brands"
                data={BrandData}
                StoreInfoContext={StoreInfoContext}
                uiContext={() => {}}
                hasFilters={false}
                hasPagination={false}
              />
            </BrandData.Provider>
          )} />

        <Route
          path="/search/:query"
          render={({ match }) => (
            <SearchData.Provider
              query={decodeURIComponent(match.params.query ?? "")}
              name={`search_${match.params.query ?? ""}`}
            >
              <SearchView />
            </SearchData.Provider>
          )} />

        <Route exact path="/kundservice">
          <Redirect to="/faq" />
        </Route>

        <Route
          exact path={t("ROUTES.ALL_GIFTCARDS")}
          render={() => (
            <AllProductsData.Provider>
              <ProductListViewWrapper
                variant="allproducts"
                data={AllProductsData}
                StoreInfoContext={StoreInfoContext}
                ProductCardComponent={ProductCard}
                uiContext={() => {}}
                hasFilters={false}
                hasPagination={false}
                productsLink={t("ROUTES.ALL_GIFTCARDS")}
                title={t("ALL_GIFTCARDS.TITLE")}
              />
            </AllProductsData.Provider>
          )}
        />

        <Route
          path="/(.+)"
          render={({ location: { pathname, state }, history }) => (
            <RouteData.Provider
              url={pathname}
              name={`route_${pathname}`}
              hint={state && state.hint}
              location={location}
            >
              <RouteView location={location} history={history} />
            </RouteData.Provider>
          )} />
      </Switch>
    </App>
  );
};

const AppRoutes = (): React$Node => <Route component={Routes} />;

export default AppRoutes;
