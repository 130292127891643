/* @flow */

import React from "react";
import useFormat from "helpers/use-format";
import { SuccessView as SuccessViewExt } from "@crossroads/shop-views";
import { useAnalytics } from "context/analytics";
import { OrderData } from "data";
import { useData } from "crustate/react";
import { load as loadOrder } from "state/order";
import { useClient } from "entrypoint/shared";
import { subscribeToNewsletter } from "queries";

const SuccessView = (): React$Node => {
  const analytics = useAnalytics();
  const orderData = useData(OrderData);
  const { formatPrice } = useFormat();

  return (
    <SuccessViewExt
      orderData={orderData}
      useClient={useClient}
      analytics={analytics}
      formatPrice={formatPrice}
      additionalField="CONTACT_INFO"
      loadOrder={loadOrder}
      mapComponent={null}
      subscribeToNewsletter={subscribeToNewsletter}
    />
  );
};

export default SuccessView;
