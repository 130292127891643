/* @flow */

import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { useData, useSendMessage } from "crustate/react";
import { addMessage } from "@crossroads/shop-state/messages";
import { QuoteData } from "data";
import { useAnalytics } from "context/analytics";
import { useClient } from "entrypoint/shared";
import { lastOrderStatus } from "queries";
import Wrapper from "components/Wrapper";
import PaymentMethods from "components/PaymentMethods";
import { Foldable, Checkbox } from "@crossroads/ui-components";
import Cart from "components/CheckoutView/Cart";
import Button from "components/Button";
import RedeemCard from "components/RedeemCard";
import { usePollOrderStatus, REDIRECT_STATUSES } from "helpers/use-poll-order-status";
import PurchaseLimitMessage from "./PurchaseLimitMessage";
import Summary from "./Summary";
import ArrowIcon from "icons/arrow.svg";
import styles from "./styles.scss";
import Cookies from "js-cookie";
import DiscountCode from "components/CheckoutView/DiscountCode";

type RedeemProps = {
  redeem: ?{ value: number },
  onSubmitting: boolean => void,
  isSubmitting: boolean,
  isLocked: boolean,
};

const CheckoutView = (): React$Node => {
  const t = useTranslate();
  const { push } = useHistory();
  const quoteData = useData(QuoteData);
  const analytics = useAnalytics();
  const client = useClient();
  const pollOrderConfig = useMemo(() => ({
    onSuccess: () => push("/checkout/success"),
  }), [push]);
  const pollOrderStatus = usePollOrderStatus(pollOrderConfig);
  const activeRedeem = quoteData.data && quoteData.data.retain24validation;
  const [isLocked, setIsLocked] = useState(false);
  const [analyticsSent, setAnalyticsSent] = useState(false);
  const [submittingCode, setSubmittingCode] = useState(false);
  const updatingQuote = ["REMOVING_ITEM", "UPDATING_ITEM"].includes(quoteData.state) || submittingCode;
  const [reciveOffers, setReciveOffers] = useState(false);

  useEffect(() => {
    if (!analyticsSent && quoteData.state === "LOADED" && quoteData.data.items.length > 0) {
      analytics.registerBeginCheckoutProcess(quoteData.data, quoteData.data.grandTotal.incVat);
      setAnalyticsSent(true);
    }
  }, [quoteData, analytics, analyticsSent]);

  // Send to SuccessView if the order is processing or complete
  // Otherwise if there is a lastOrder, start polling status
  useEffect(() => {
    (async () => {
      if (quoteData.state === "LOADED" && quoteData.data.items.length === 0) {
        const { lastOrder } = await client(lastOrderStatus);

        if (lastOrder) {
          if (!REDIRECT_STATUSES.includes(lastOrder.status)) {
            pollOrderStatus();
          }
          else if (REDIRECT_STATUSES.includes(lastOrder.status)) {
            push("/checkout/success");
          }
        }
      }
    })();
  }, [quoteData.state]);

  useEffect(() => {
    Cookies.set("opt-email", reciveOffers.toString());
  }, [reciveOffers]);

  if (!quoteData.data) {
    return null;
  }

  if (quoteData.state === "LOADED" && quoteData.data.items.length === 0) {
    return (
      <Wrapper className={styles.wrapper}>
        <Helmet title={t("CHECKOUT.TITLE")} />
        <header className={styles.header}>
          <h1>{t("CART.EMPTY")}</h1>
          <Button className={styles.back} to="/">
            <ArrowIcon />
            <span>{t("CHECKOUT.CONTINUE_SHOPPING")}</span>
          </Button>
        </header>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={styles.wrapper}>
      <Helmet title={t("CHECKOUT.TITLE")} />

      <header className={styles.header}>
        <h1>{t("CHECKOUT.TITLE")}</h1>
        <Button className={styles.back} to="/">
          <ArrowIcon />
          <span>{t("CHECKOUT.CONTINUE_SHOPPING")}</span>
        </Button>
      </header>

      <div className={styles.body}>
        <div className={styles.cart}>
          <Cart
            isLocked={isLocked}
          />

          <PurchaseLimitMessage />

          <Summary />

          <div className={styles.ctaContainer}>
            <Redeem
              redeem={activeRedeem}
              isLocked={isLocked}
              isSubmitting={submittingCode}
              onSubmitting={setSubmittingCode}
            />

            <div className={styles.optEmail}>
              <Checkbox
                name="opt-out"
                value={reciveOffers}
                defaultChecked={reciveOffers}
                onChange={e => setReciveOffers(e.target.checked)}
              >
                {t("CHECKOUT.OPT_EMAIL")}
              </Checkbox>
            </div>
          </div>
        </div>

        <PaymentMethods
          className={styles.paymentMethods}
          quote={quoteData.data}
          updatingQuote={updatingQuote}
          isLocked={isLocked}
          setIsLocked={setIsLocked}
        />
      </div>

    </Wrapper>
  );
};

const QUOTE_ITEM_UPDATING_STATES = new Set([
  "UPDATING_ITEM",
  "REMOVING_ITEM",
]);

const Redeem = ({ redeem, isSubmitting, onSubmitting, isLocked }: RedeemProps) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const [redeemOpen, setRedeemOpen] = useState(false);
  const [discountOpen, setDiscountOpen] = useState(false);
  const [updatingQuote, setUpdatingQuote] = useState<boolean>(false);
  const data = useData(QuoteData);

  useEffect(() => {
    if (QUOTE_ITEM_UPDATING_STATES.has(data.state)) {
      setUpdatingQuote(true);
    }
    else {
      setUpdatingQuote(false);
    }
  }, [data.state]);

  if (redeem && !data.data) {
    return null;
  }

  const coupon = data.data && data.data.coupon;

  const onSuccess = () => {
    sendMessage(addMessage("success", "success"));
  };

  return (
    <div className={styles.redeem}>
      <div className={styles.redeemInner}>
        <div className={styles.redeemButtons}>
          <Button
            disabled={discountOpen}
            className={styles.discountBtn}
            variant="ghost"
            onClick={() => {
              if (!isSubmitting) {
                setDiscountOpen(!discountOpen);
                setRedeemOpen(false);
              }
            }}
          >
            {t("CHECKOUT.DISCOUNT_CODE.CHECKBOX_LABEL")}
          </Button>
          <Button
            disabled={redeemOpen}
            variant="primary"
            onClick={() => {
              if (!isSubmitting && !isLocked) {
                setRedeemOpen(!redeemOpen);
                setDiscountOpen(false);
              }
            }}
          >
            {t("CHECKOUT.REDEEM_BUTTON_LABEL")}
          </Button>
        </div>
        <div className={styles.redeemCard}>
          <Foldable open={redeemOpen || discountOpen} className={styles.redeemFoldable}>
            {redeemOpen &&
              <RedeemCard
                isLocked={isLocked}
                className={styles.redeemCardInner}
                onSubmitting={onSubmitting}
                onSuccess={onSuccess}
              />
            }

            {discountOpen &&
              <DiscountCode
                disabled={updatingQuote}
                coupon={coupon}
              />
            }
          </Foldable>
        </div>
      </div>
    </div>
  );
};

export default CheckoutView;
