/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { Link } from "react-router-dom";
import { HomeData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { useBrowser } from "@awardit/react-use-browser";
import { Carousel, Combined, Dots } from "@crossroads/ui-components";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import useDevice from "helpers/use-device";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import { formatMeta } from "helpers/get-meta";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import ProductList from "components/ProductList";
import Tutorial from "components/Tutorial";
import PopularBrands from "components/PopularBrands";
import ArrowIcon from "icons/arrow.svg";
import Slider from "components/Slider";

import styles from "./styles.scss";
import { useViewMode } from "context/view-mode";

const enrichImagesData = (data: any) => {
  return data.map(s => ({
    ...s,
    images: [
      { ...s.images.large, media: `screen` },
    ],
  }));
};

const HomeView = (): React$Node => {
  const browser = useBrowser();
  const t = useTranslate();
  const data = useData(HomeData);
  const { info } = useContext(StoreInfoContext);
  const isDesktop = useDevice("gt", 550);
  const { width: browserWidth } = useBrowserDimensions();

  const [, setMode] = useViewMode();

  if (data.state !== "LOADED") {
    return null;
  }

  const popularProducts = data.data.featuredProducts ?
    data.data.featuredProducts.category.products.items :
    [];
  const slideData = (Object.values(data.data): any)
    // $FlowIssue[method-unbinding]
    .filter(e => e && Object.prototype.hasOwnProperty.call(e, "carousel"))
    .map(c => ({
      name: c.carousel.name,
      url: c.carousel.url,
      image1x: c.carousel.image && c.carousel.image.x1,
      image2x: c.carousel.image && c.carousel.image.x2,
    }));

  const categorySlides = slideData.map(s => (
    <Link
      key={s.image}
      className={styles.carouselItem}
      style={{ display: "block" }}
      to={{
        pathname: s.url,
        state: { hint: { type: "category", category: { name: s.name } } },
      }}
    >

      <picture className={styles.image}>
        <source srcSet={`${s.image1x} 1x, ${s.image2x} 2x`} />
        <img alt={s.name} src={s.image1x} />
      </picture>

      <div className={styles.titleWrapper}>
        <h4 className={styles.title}>{s.name}</h4>
        <p>{s.description}</p>
      </div>
    </Link>
  ));

  const heroData = (Object.values(data.data): any)
    // $FlowIssue[method-unbinding]
    .filter(e => e && Object.prototype.hasOwnProperty.call(e, "block"))
    .map(c => ({
      heading: c.block.heading,
      description: c.block.text,
      url: c.block.url,
      images: {
        large: {
          url: c.block.largeImage && c.block.largeImage.x2,
          dataUrl: c.block.largeImage && c.block.largeImage.thumb,
        },
      },
      button: {
        text: c.block.cta,
        link: c.block.link,
      },
    }));

  const meta = formatMeta({
    title: info.defaultTitle,
    description: info.defaultDescription,
  });

  const visiblePopularProducts = () => {
    if (browserWidth < Number.parseInt(styles.small, 10)) {
      return 2;
    }

    if (browserWidth < Number.parseInt(styles.medium, 10)) {
      return 3;
    }

    return 4;
  };

  const itemsPerRow = () => {
    if (browserWidth < Number.parseInt(styles.small, 10)) {
      return 2;
    }

    if (browserWidth < Number.parseInt(styles.medium, 10)) {
      return 3;
    }

    return 4;
  };

  return (
    <div className={styles.block}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      <Wrapper className={styles.topBlock}>
        <section className={styles.slider}>
          <Slider slides={enrichImagesData(heroData)} />
        </section>
      </Wrapper>

      <Wrapper>
        <section className={styles.popularCategories}>
          <div className={styles.headingSplit}>
            <h2 className={styles.heading}>{t("HOMEVIEW.POPULAR_CATEGORIES")}</h2>

            <Button
              variant="blank"
              className={styles.cta}
              onClick={() => setMode("categories")}
            >
              {t("HOMEVIEW.ALL_CATEGORIES")}
            </Button>
          </div>

          <div className={styles.carouselWrapper}>
            <Carousel
              slidesToScroll={itemsPerRow()}
              slidesToShow={itemsPerRow()}
              items={categorySlides}
            >
              {isDesktop ?
                <Combined icon={<ArrowIcon />} className={styles.carouselControls} /> :
                <Dots className={styles.carouselControls} />
              }
            </Carousel>
          </div>
        </section>

      </Wrapper>

      <Wrapper>
        <PopularBrands />
      </Wrapper>

      {(browser && Array.isArray(popularProducts) && popularProducts.length > 0) &&
        <Wrapper>
          <div className={styles.popularProducts}>
            <div className={styles.headingSplit}>
              <h2 className={styles.heading}>{t("HOMEVIEW.POPULAR_PRODUCTS")}</h2>
              {data.data.featuredProducts &&
                <Button
                  variant="blank"
                  className={styles.cta}
                  to={{
                    pathname: data.data.featuredProducts.category.url,
                    state: { hint: {
                      type: "category",
                      category: {
                        name: "Populära produkter",
                      },
                    } },
                  }}
                >
                  {t("HOMEVIEW.MORE_POPULAR_PRODUCTS")}
                </Button>
              }
            </div>
            <ProductList
              className={styles.products}
              products={popularProducts.slice(0, visiblePopularProducts())}
              listName={t("HOMEVIEW.POPULAR_PRODUCTS")}
            />
          </div>
        </Wrapper>
      }

      <Wrapper>
        <h2 id="about" className={cn(styles.heading, styles.headingTutorial)}>{t("HOMEVIEW.TUTORIAL")}</h2>
        <Tutorial />
      </Wrapper>
    </div>
  );
};

export default HomeView;
