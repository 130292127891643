/* @flow */

import type { Location } from "react-router";

import React, { useEffect, useState, useRef } from "react";
import usePrevious from "helpers/use-previous";
import cn from "classnames";
import { debounce } from "diskho";
import styles from "./styles.scss";
import AppHeaderSmall from "./Small";
import AppHeaderLarge from "./Large";
import { CustomerData, QuoteData } from "data";
import { useData } from "crustate/react";
import { useSubNavOpen } from "context/sub-nav-open";
import { useViewMode } from "context/view-mode";

type Props = {
  className: string,
  onCheckout: boolean,
  location: Location,
};

const AppHeader = (props: Props): React$Node => {
  const { location: { pathname } } = props;
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const customerData = useData(CustomerData);
  const quoteData = useData(QuoteData);
  const prevQuoteData = usePrevious(quoteData);
  const customer = typeof customerData.data !== "undefined" ? customerData.data : null;
  const [, setSubNavOpen] = useSubNavOpen();
  const [viewMode, setViewMode] = useViewMode();

  const [isScrolled, setIsScrolled] = useState(false);

  const setIsScrolledDebounced = useRef(debounce(() => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    }
    else {
      setIsScrolled(false);
    }
  }, 10)).current;

  useEffect(() => {
    window.addEventListener("scroll", setIsScrolledDebounced, { passive: true });

    return () => {
      if (setIsScrolledDebounced) {
        window.removeEventListener("scroll", setIsScrolledDebounced);
      }
    };
  }, [setIsScrolledDebounced]);

  // Close menus when switching page
  useEffect(() => {
    setViewMode("normal");
    setSubNavOpen(false);
    setHamburgerOpen(false);
  }, [setViewMode, setSubNavOpen, pathname]);

  // Open mini cart when adding to cart
  useEffect(() => {
    if (pathname === "/checkout" ||
        !prevQuoteData ||
        (quoteData.state !== "LOADED" || prevQuoteData.state !== "ADDING_ITEM")) {
      return;
    }

    const count = quoteData.data.items.reduce((a, { qty }) => a + qty, 0);
    const prevCount = prevQuoteData.data.items.reduce((a, { qty }) => a + qty, 0);

    if (count > prevCount) {
      setViewMode("cart");
      setSubNavOpen(false);
      setHamburgerOpen(false);
    }
  }, [setViewMode, prevQuoteData, quoteData, pathname]);

  return (
    <div>
      <div className={cn(styles.container, { [styles.active]: isScrolled || viewMode !== "normal" })}>
        <AppHeaderSmall
          {...props}
          customer={customer}
          hamburgerOpen={hamburgerOpen}
          setHamburgerOpen={setHamburgerOpen}
        />
        <AppHeaderLarge
          {...props}
          customer={customer}
        />
      </div>

      <div className={styles.height} />
    </div>
  );
};

export default AppHeader;
