/* @flow */

import React, { useState } from "react";

import cn from "classnames";
import { useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { Input } from "@crossroads/ui-components";
import Button from "components/Button";
import { setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";
import CloseIcon from "icons/cross.svg";
import ChevronIcon from "icons/chevron.svg";

import styles from "./styles.scss";

const DiscountCode = ({ coupon = { code: "", label: "" }, setSummaryOpen, disabled, setOpen }: any): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const [discountCode, setDiscountCode] = useState<string>(coupon ? coupon.code : "");

  const removeCode = () => {
    sendMessage(removeQuoteDiscountCode());
    setDiscountCode("");
    setSummaryOpen(true);
    setOpen(false);
  };

  const onSubmit = (e?: Event) => {
    if (e) {
      e.preventDefault();
    }

    if (discountCode.length === 0 || disabled === true) {
      return;
    }

    sendMessage(setQuoteDiscountCode(discountCode));
    setSummaryOpen(true);
  };

  const onRemove = (e?: Event) => {
    if (e) {
      e.preventDefault();
    }

    if (disabled === true) {
      return;
    }

    removeCode();
  };

  return (
    <div className={cn(styles.discountCodeWrapper, { [styles.disabled]: coupon })}>
      <Input
        className={styles.input}
        placeholder={t("CHECKOUT.DISCOUNT_CODE.HEADER")}
        label={t("CHECKOUT.DISCOUNT_CODE.CHECKBOX_LABEL")}
        name="discountCode"
        disabled={coupon}
        value={discountCode}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          setDiscountCode(e.currentTarget.value);
        }}
        onKeyUp={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
      />

      <Button
        className={styles.buttonCode}
        variant="primary"
        onClick={coupon ? onRemove : onSubmit}
      >
        <span>{coupon ? t("CHECKOUT.DISCOUNT_CODE.REMOVE") : t("CHECKOUT.DISCOUNT_CODE.APPLY")}</span>
        {coupon ? <CloseIcon /> : <ChevronIcon />}

      </Button>
    </div>
  );
};

export default DiscountCode;
