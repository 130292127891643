/* @flow */

import type { Product, ProductCardProduct, ProductCategory } from "shop-state/types";
import type { Option } from "@crossroads/ui-components";

import React, { useState, useEffect, useMemo } from "react";
import { useAnalytics } from "context/analytics";
import cn from "classnames";
import { useData } from "crustate/react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { useGetProductMeta } from "helpers/get-meta";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import useSessionStorage from "helpers/use-session-storage";
import { QuoteData } from "data";
import styles from "./styles.scss";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import Breadcrumbs from "components/Breadcrumbs";
import { Button, AutoExpand, AddToCart, getPrice, inStock, productDefaults, getAttributesConfigurable, getSelectedConfigurable, ProductViewMedia } from "@crossroads/ui-components";
import Price from "./price";
import { CardValueSelect } from "components/ProductOptions/card-value";

type ProductViewProps = {
  product: Product,
};

type HintProductViewProps = {
  product: ProductCardProduct,
};

const mapProductCategories = (categories: $ReadOnlyArray<ProductCategory>) => {
  const structuredCategories: Array<{
      name: string,
      url: string,
      children: Array<{
        name: string,
        url: string,
      }>,
  }> = [];

  for (const category of categories) {
    if (category.parent) {
      let parent = structuredCategories.find(c => c.url === category.parent?.url);

      if (parent) {
        parent.children.push({
          name: category.name,
          url: category.url,
        });
      }
      else {
        parent = {
          name: category.parent?.name || "",
          url: category.parent?.url || "",
          children: [{
            name: category.name,
            url: category.url,
          }],
        };

        structuredCategories.push(parent);
      }
    }
    else {
      structuredCategories.push({
        name: category.name,
        url: category.url,
        children: [],
      });
    }
  }

  return structuredCategories;
};

const AddToCartBtn = ({
  isLoading = false, outOfStock,
}: { isLoading?: boolean, outOfStock: boolean,
}) => {
  const t = useTranslate();
  const quoteState = useData(QuoteData);
  const addingToCart = quoteState.state === "ADDING_ITEM";

  return (
    <div className={styles.addToCartWrapper}>
      <Button
        className={styles.addToCart}
        variant="primary"
        disabled={outOfStock}
        loading={addingToCart || isLoading}
        type="submit"
      >
        {outOfStock ? t("PRODUCT.OUT_OF_STOCK") : t("PRODUCT.ADD_TO_CART")}
      </Button>
    </div>
  );
};

const ProductView = ({ product }: ProductViewProps): React$Node => {
  const location = useLocation();
  const analytics = useAnalytics();
  const configAttributes = product.type === "configurable" ? getAttributesConfigurable(product) : {};
  const [selected, setSelected] = useState<Option>(productDefaults(product));
  const selectedItem = getSelectedConfigurable(selected, configAttributes);
  const categories = useMemo(() => mapProductCategories(product.categories), [product]);
  const breadcrumbLinks = useBreadcrumbLinks({ fallbackCategories: categories });
  const [isolationMode] = useSessionStorage("navIsolationMode", "false");
  const navIsolationMode = isolationMode === "true";

  const t = useTranslate();
  const meta = useGetProductMeta(product, t);

  const outOfStock = !inStock(product, selected);

  const [currentImage, setCurrentImage] = useState({
    smallImage: product.attributes.smallImage ?
      { x1: product.attributes.smallImage.x1, x2: product.attributes.smallImage.x2 } : null,
    largeImage: product.attributes.largeImage ?
      { x1: product.attributes.largeImage.x1, x2: product.attributes.largeImage.x2 } : null,
  });

  useEffect(() => {
    analytics.registerProductDetailsView({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    },
    product.price.incVat,
    location.state?.list,
    location.state?.position);
  }, []);

  const onAdd = p => {
    if (selectedItem) {
      const incVat = getPrice(product, selected, "incVat");
      const exVat = getPrice(product, selected, "exVat");

      analytics.registerModifyCart({
        ...selectedItem.product,
        qty: 1,
        price: { incVat, exVat, vat: incVat - exVat },
        categories: p.categories,
      }, 1, incVat);
    }
    else {
      analytics.registerModifyCart({
        ...p,
        qty: 1,
      }, 1, p.price.incVat);
    }
  };

  // Change image when selecting option
  useEffect(() => {
    if (selectedItem) {
      const a = selectedItem.product.attributes;

      if (a) {
        setCurrentImage({
          smallImage: a.smallImage ? { x1: a.smallImage.x1, x2: a.smallImage.x2 } : null,
          largeImage: a.largeImage ? { x1: a.largeImage.x1, x2: a.largeImage.x2 } : null,
        });
      }
    }
  }, [selected]);

  return (
    <Wrapper>
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      <Breadcrumbs
        root={navIsolationMode ? null : undefined}
        links={breadcrumbLinks}
        current={product.name}
      />

      <div className={styles.block}>
        <div className={styles.left}>
          <ProductViewMedia
            alt={product.name}
            currentImage={currentImage}
            gallery={product.gallery}
            galleryPerRow={2}
            location={{ ...location }}
          />
        </div>
        <div className={styles.right}>
          <header className={styles.header}>
            <h1 className={styles.name}>{product.name}</h1>
            {((product.type === "configurable" && product.attributes.showConfigPrice) ||
            product.type !== "configurable") &&
              <Price price={getPrice(product, selected, "incVat")} />
            }
          </header>

          <AddToCart
            product={product}
            selected={selected}
            setSelected={setSelected}
            qty={1}
            templates={{
              value: CardValueSelect,
            }}
            onAdd={onAdd}
          >
            <AddToCartBtn outOfStock={outOfStock} />
          </AddToCart>

          <section>
            <h3 className={cn("h2", styles.descriptionHeading)}>{t("PRODUCT.DESCRIPTION")}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
              className={styles.description}
            />
          </section>
        </div>
      </div>

      <AutoExpand className={styles.lists}>
        <div className={styles.relatedList}>
          {product.relatedProducts.items.length > 0 &&
            <ProductList
              heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
              products={product.relatedProducts.items}
              productsPerRow={2}
              listName={t("PRODUCT.OTHERS_ALSO_LIKED")}
            />
          }
        </div>

        <div className={styles.historyList}>
          {product.crossSellProducts.items.length > 0 &&
            <ProductList
              heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
              products={product.crossSellProducts.items}
              productsPerRow={2}
              listName={t("PRODUCT.OTHERS_ALSO_LIKED")}
            />
          }
        </div>
      </AutoExpand>
    </Wrapper>
  );
};

export const Hint = ({ product }: HintProductViewProps): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const breadcrumbLinks = useBreadcrumbLinks();
  const [isolationMode] = useSessionStorage("navIsolationMode", "false");
  const navIsolationMode = isolationMode === "true";
  return (
    <Wrapper>
      <Breadcrumbs
        root={navIsolationMode ? null : undefined}
        links={breadcrumbLinks}
        current={product.name}
      />

      <div className={styles.block}>
        <div className={styles.left}>
          <ProductViewMedia
            alt={product.name}
            currentImage={{
              largeImage: product.attributes.largeImage,
              smallImage: product.attributes.smallImage,
            }}
            gallery={[]}
            galleryPerRow={2}
            location={{ ...location }}
          />
        </div>
        <div className={styles.right}>
          <header className={styles.header}>
            <h1 className={styles.name}>{product.name}</h1>
            {product.type !== "configurable" &&
              <Price price={product.price.incVat} />
            }
          </header>

          <section>
            <h3 className={styles.descriptionHeading}>{t("PRODUCT.DESCRIPTION")}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
              className={styles.description}
            />
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

export const HintProductView = Hint;

export default ProductView;
