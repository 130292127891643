/* @flow */

import type { AbstractAddressInputFormaggio, Quote } from "shop-state/types";

import React, { useEffect, useContext } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { useSendMessage } from "crustate/react";
import { addMessage } from "@crossroads/shop-state/messages";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import CollectorCheckout, { METHOD_CODE } from "./CollectorCheckout";
import { syncQuote } from "@crossroads/shop-state/quote";
import {
  quote as quoteQuery,
  setQuotePaymentMethodCollectorCheckout } from "queries";
import cn from "classnames";

import styles from "./styles.scss";

export type FormDataValue = boolean | string | number | FormData;

export type FormData = { [key: string]: FormDataValue };

type Props = {
  setProcessing: boolean => void,
  updatingQuote: boolean,
  quote: Quote,
  className?: string,
};

export type FormState = {
  billing: AbstractAddressInputFormaggio,
  email: string,
};

const Collector = ({ setProcessing, quote, updatingQuote, className }: Props): React$Node => {
  const t = useTranslate();
  const client = useClient();
  const sendMessage = useSendMessage();
  const { info: { baseUrl } } = useContext(StoreInfoContext);

  // Set paymentmethod to collector-checkout
  useEffect(() => {
    (async () => {
      if (quote.payment?.code !== METHOD_CODE || (quote.payment && !quote.payment.publicToken)) {
        const successUri = baseUrl + "checkout/success";
        const cancelUri = baseUrl + "checkout";

        await client(setQuotePaymentMethodCollectorCheckout, { successUri, cancelUri });
        const newQuoteData = await client(quoteQuery);

        if (newQuoteData.quote) {
          sendMessage(syncQuote(newQuoteData.quote));
        }
      }
    })();
  }, [quote.payment]);

  return (
    <div className={cn(styles.block, className)}>
      {quote.payment &&
        quote.payment.code === METHOD_CODE &&
        quote.payment.publicToken &&
        <CollectorCheckout
          className={styles.collector}
          updatingQuote={updatingQuote}
          publicToken={quote.payment.publicToken}
          lang={t("LOCALE.ISO.IETF")}
          onLocked={() => setProcessing(true)}
          onOrderValidationFailed={() => setProcessing(false)}
          onUnlocked={() => {
            setProcessing(false);
          }}
          onResumed={() => {
            setProcessing(false);
          }}
          onPurchaseCompleted={() => {
            setProcessing(false);
          }}
          onExpired={() => {
            sendMessage(addMessage("COLLECTOR.SESSION_EXPIRED", "error"));
            setProcessing(false);
          }}
        />
      }
    </div>
  );
};

export default Collector;
