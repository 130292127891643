/* @flow */

/*
  Load pictures from upscaled and blurred to full size.
*/

import React, { Component } from "react";
import styles from "./styles.scss";
import cn from "classnames";

export type Image = {
  dataUrl?: string,
  url: string,
  media: string,
};

type Props = {
  className?: string,
  images: Array<Image>,
};

type State = {
  loaded: boolean,
  sources: Array<{ dataUrl?: string, url: string, media: string }>,
};

// Disable eslint for this file because of legacy code
/* eslint-disable react/state-in-constructor */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable react/static-property-placement */

export default class BlurUpPicture extends Component<Props, State> {
  props: Props;
  state: State;
  image: HTMLElement | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      loaded: false,
      sources: props.images,
    };
  }

  loadImage(props: Props) {
    if (!this.image) {
      return;
    }

    const activeSource = props.images.find(source => this.image?.getAttribute("src") === source.dataUrl);

    if (!activeSource) {
      return;
    }

    const downloadImage = new window.Image();

    const setLoadedState = () => {
      this.setState({
        loaded: true,
        sources: props.images.map(({ dataUrl, ...source }) => ({ ...source })),
      });

      downloadImage.removeEventListener("load", setLoadedState);
    };

    downloadImage.addEventListener("load", setLoadedState);
    downloadImage.src = activeSource.url;
  }

  componentDidMount() {
    this.loadImage(this.props);
  }

  render(): React$Node {
    const { loaded, sources } = this.state;

    return (
      <picture
        className={cn(
          this.props.className,
          styles.bluruppicture,
          { [styles.isLoaded]: loaded }
        )}
      >

        {sources.map((source, i) => (
          <source
            key={i}
            srcSet={source.dataUrl || source.url}
            media={source.media}
          />
        ))}

        {sources.length > 0 &&
          <img
            ref={img => {
              this.image = img;
            }}
            draggable="false"
            src={sources[0].dataUrl || sources[0].url}
            srcSet={sources[0].dataUrl || sources[0].url}
            className={styles.bluruppicture__img}
          />
        }
      </picture>
    );
  }
}

/* eslint-enable react/state-in-constructor */
/* eslint-enable prefer-arrow/prefer-arrow-functions */
/* eslint-enable react/static-property-placement */
