/* @flow */

import React from "react";
import { useFormField } from "@awardit/formaggio";
import { Input, Checkbox, Foldable } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";

type CheckboxProps = {
  ...React$ElementProps<"input">,
  children: React$Node,
};

const Field = (props: React$ElementProps<"input">): React$Node => {
  const t = useTranslate();
  const { submitted, dirty, errors, inputProps } = useFormField(props.name, "");

  const inputErrors = submitted || dirty ?
    errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <Input {...props} {...inputProps} errors={inputErrors} />
  );
};

export default Field;

export const CheckboxField = ({ children, name, ...props }: CheckboxProps): React$Node => {
  const t = useTranslate();
  const fieldProps = useFormField(name, "");

  const inputErrors = fieldProps.submitted || fieldProps.dirty ?
    fieldProps.errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          {...props}
          {...fieldProps.inputProps}
          value={fieldProps.inputProps.value}
          name={name}
        />
        <label htmlFor={fieldProps.inputProps.id} style={{ paddingLeft: "0.7em" }}>
          {children}
        </label>

      </div>
      <Foldable className={styles.errors} open={inputErrors.length > 0}>
        {inputErrors.map(x => <span key={x}>{x}</span>)}
      </Foldable>
    </>
  );
};
