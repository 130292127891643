/* @flow */

import React, { useRef } from "react";
import { useClient } from "entrypoint/shared";
import { lastOrderStatus } from "queries";

type Props = {
  setProcessing?: boolean => void,
  onSuccess?: () => void,
  onError?: () => void,
};

type UsePollOrderStatusReturnType = (numTries?: number) => Promise<void>;

const POLL_TIMER_MS = 1000;
const POLL_MAX_TRIES = 15;

export const REDIRECT_STATUSES = ["processing", "complete", "payment_review"];

export const usePollOrderStatus =
  ({ setProcessing, onError, onSuccess }: Props): UsePollOrderStatusReturnType => {
    const timer = useRef();
    const client = useClient();

    const pollOrderStatus = async (numTries?: number = POLL_MAX_TRIES) => {
      if (!timer.current) {
        if (setProcessing) {
          setProcessing(true);
        }

        timer.current = true; // Set to true, to prevent possible race-condition

        const { lastOrder } = await client(lastOrderStatus);

        if (REDIRECT_STATUSES.includes(lastOrder?.status || "")) {
          if (onSuccess) {
            onSuccess();
          }
        }
        else if (numTries - 1 === 0) {
          if (setProcessing) {
            setProcessing(false);
          }

          if (onError) {
            onError();
          }
        }
        else {
          timer.current = setTimeout(() => {
            timer.current = null;
            pollOrderStatus(numTries - 1);
          }, POLL_TIMER_MS);
        }
      }
    };

    return pollOrderStatus;
  };
